import SeoBoundary from 'lib/boundaries/SeoBoundary'
import { deleteAllCookies } from 'lib/hooks/useLogout'
import { IDefaultLayout } from 'lib/layouts/DefaultLayout/types'
import AlternativePaymentsLogo from 'lib/ui/Icon'
import Text from 'lib/ui/Text'
import Image from 'next/image'
import {
  getEnvironmentByDomain,
  getPartnerAppDomain,
  paymentsPortalLocalUrl,
  Environment
} from 'utils/env'

import * as css from './styles'
import { usePartnerContext } from '@/hooks/providers/PartnerProvider'
import Button from '@/ui/Button/v2'

interface IErrorLayout extends Pick<IDefaultLayout, 'headline' | 'title'> {
  message?: React.ReactNode
}
const ErrorLayout = ({
  headline = 'Sorry',
  message = 'You do not have access to this resource or this page does not exist.',
  title = 'Alternative Payments - Page does not exists'
}: IErrorLayout) => {
  const { partner } = usePartnerContext()
  const handleBackToDashboard = () => {
    deleteAllCookies()

    if (!partner) {
      window.location.href = getPartnerAppDomain(window.location.origin)
      return
    }

    const isDevelopment =
      getEnvironmentByDomain(window.location.origin) === Environment.Local
    const url = isDevelopment
      ? paymentsPortalLocalUrl
      : 'https://' + partner.paymentsPortalUrl
    window.location.href = url
  }

  return (
    <SeoBoundary title={title}>
      <div data-testid='error-page' css={css.body}>
        <div css={css.messageWrapper}>
          <div css={css.logo}>
            <AlternativePaymentsLogo
              name='alternativePaymentsLogo'
              width={15}
              height={5}
              viewBox='0 0 170 35'
              color='black'
            />
          </div>
          <Text styles={css.title}>{headline}</Text>
          <Text styles={css.message}>{message}</Text>
          <Button styles={css.button} onClick={handleBackToDashboard}>
            Back to Dashboard
          </Button>
        </div>
        <div css={css.imageContainer}>
          <div css={css.imageWrapper}>
            <Image
              src='/error-hero-image.png'
              alt={title}
              fill
              objectFit='contain'
            />
          </div>
        </div>
      </div>
    </SeoBoundary>
  )
}

export default ErrorLayout
